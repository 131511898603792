<template>
  <div :class="{ 'setting-container': !shadow, 'setting-container-with-shadow': shadow }">
    <AppCard class="setting-card" centered>
      <template #header>
        <slot name="pageTitle"></slot>
        <AppCardHeader class="no-padding">
          <template #left>
            <h1 class="text-secondary-text">{{ title }}</h1>
          </template>
          <template #right>
            <h1 class="cursor-pointer" @click="$emit('edit')">{{ ctaLabel }}</h1>
          </template>
        </AppCardHeader>
        <p v-if="subHeader" class="sub-header">{{ subHeader }}</p>
      </template>
      <div class="setting-content">
        <div v-if="fields">
          <XeAppDescriptionList v-for="(field, index) in fields" :key="index" borderTop>
            <AppDescriptionListItem>
              <template #title>{{ field.label }}</template>
              <template #content>
                <p>{{ getValue(field.value, user, field.type, field.default) }}</p>
              </template>
            </AppDescriptionListItem>
          </XeAppDescriptionList>
        </div>
        <slot name="subheader"> </slot>
        <slot />
      </div>
    </AppCard>
  </div>
</template>

<script>
import XeAppDescriptionList from '@galileo/components/XeAppDescriptionList/XeAppDescriptionList'
import { useCountriesStore } from '@galileo/stores'
import { AppCard, AppCardHeader, AppDescriptionListItem } from '@oen.web.vue2/ui'

export default {
  name: 'SettingsCard',
  components: {
    AppCard,
    XeAppDescriptionList,
    AppDescriptionListItem,
    AppCardHeader,
  },
  emit: ['click', 'edit'],
  props: {
    user: {
      type: Object,
      default: null,
    },
    fields: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    subHeader: {
      type: String,
      default: '',
    },
    //call to action Label and link
    ctaLabel: {
      type: String,
      default: '',
    },
    ctaLink: {
      type: String,
      default: '',
    },
    shadow: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const countriesStore = useCountriesStore()
    const languages = countriesStore.getShortAvailableLanguagesWithLabel
    const countries = countriesStore.getCountries

    function getCountry(country) {
      let result = country
      result = countries.find((cty) => cty.value === country)
      return result.text
    }

    function getLanguage(language) {
      let result = language
      result = languages.find((lang) => lang.value === language)
      return result?.label ?? language
    }

    let getValue = function (value, data, type = null, def = null) {
      if (value != null && value.includes('.')) {
        let dotIndex = value.indexOf('.')
        let firstPart = value.substring(0, dotIndex)
        let obj = data[firstPart]
        return getValue(value.substring(dotIndex + 1), obj, def)
      } else {
        let result = data[value]
        if (result == null || result === '') {
          return def
        }

        if (type === 'country') {
          result = getCountry(result)
        }

        if (type === 'language') {
          result = getLanguage(result)
        }

        return result
      }
    }

    return { getValue }
  },
}
</script>

<style scoped>
.setting-container > .card {
  @apply box-border p-4;
  /* border: 0.5px solid #dddddd; */
  @screen sm {
    @apply p-12 mb-6;
  }
}

.setting-container-with-shadow > .card {
  @apply mb-6 box-border shadow-ria-1;
  border: 0.5px solid #dddddd;
}

::v-deep .card.setting-card > .card-content {
  @apply px-0 text-sm font-medium pt-0;
}

::v-deep .description-list {
  @apply py-2.5;
}

.setting-card ::v-deep .card-content .card-content-block {
  @apply mb-0;
}

.setting-content ::v-deep .card-content .card-content-block {
  @apply mb-6;
}

::v-deep .description-list-item {
  @apply items-center;
}

::v-deep .description-list-item .description-list-item-term {
  @apply font-medium leading-6;
  font-size: 15px;
}

::v-deep .description-list-item .description-list-item-definition {
  @apply align-middle items-center;
  @apply text-sm leading-5 text-gray-text;
}

::v-deep .button.button--icon {
  @apply ml-2;
  .button-inner {
    @apply flex w-auto h-auto rounded-none;
  }
}

.no-padding.card-header {
  @apply p-0;
}

::v-deep .card-header-left {
  @apply text-base font-semibold uppercase text-tertiary-text;
  font-size: 10px;
}

.sub-header {
  @apply px-6 font-medium normal-case text-sm text-tertiary-text;
}

::v-deep .card-header-right {
  @apply text-base uppercase font-semibold text-blue;
  font-size: 10px;
}

::v-deep .card-header-left h1 {
  @apply font-semibold leading-4 text-gray-text;
  letter-spacing: 1px;
  font-size: 10px;
}
::v-deep .card {
  @apply border-none;
  max-width: 38rem;
}
</style>
