<template>
  <div>
    <AppModal
      v-show="!showBillingAddressEditModal"
      v-model="model"
      :class="{ 'add-margin': isCvv }"
      :title="isCvv ? $t('AppPaymentCardModal.ConfirmPaymenByCardTitle').value : editCardTitle"
    >
      <template #header="{ dismiss }">
        <AppModalHeader>
          <template #left>
            <XeBackButton
              icon="<"
              :name="$t('AppPaymentCardModal.GoBackTextButton').value"
              analytics-name="card-details-modal-back"
              @click="dismiss"
            />
          </template>

          <h1 v-if="isCvv">{{ $t('AppPaymentCardModal.ConfirmPaymenByCardTitle').value }}</h1>
          <h1 v-else>{{ editCardTitle }}</h1>
          <!-- <h1 v-else>{{ $t('AppPaymentCardModal.EditCardTitle').value }} </h1> -->

          <template #right>
            <AppBackButton
              v-if="isCvv"
              analytics-name="add-payment-card-modal-back-right"
              @click="close"
            />
            <AppButton
              v-else
              theme="icon"
              class="icon icon-trash-can"
              analytics-name="card-details-modal-delete"
              @click="onDelete"
            >
              <AppIcon :name="$t('AppPaymentCardModal.DeleteTextButton').value">
                <IconTrashCan />
              </AppIcon>
            </AppButton>
          </template>
        </AppModalHeader>
      </template>

      <p class="card-details-title">
        {{ $t('ComponentAddPaymentCardModal.CardDetailsText').value }}
      </p>
      <p class="card-details-description">
        {{ $t('ComponentAddPaymentCardModal.CardDetailsDescription').value }}
      </p>

      <AppInputMask
        disabled
        :value="dottedCardNumber"
        analytics-name="add-payment-card-modal-cardnumber"
        :label="$t('ComponentAddPaymentCardModal.CardNumberLabel').value"
        :placeholder="dottedCardNumber"
        autocomplete="off"
        type="text"
        inputmode="text"
      >
        <template #rightIcon>
          <Transition name="fade" mode="out-in">
            <div class="card-images">
              <img
                v-if="!form.cardBrand || form.cardBrand === 'MASTERCARD'"
                src="@galileo/assets/images/cards/mastercard-black.svg"
                alt="Mastercard"
                :class="{
                  'selected-card': !form.cardBrand,
                }"
              />
              <img
                v-if="!form.cardBrand || form.cardBrand === 'VISA'"
                src="@galileo/assets/images/cards/visa-black.svg"
                alt="Visa"
                :class="{
                  'selected-card': !form.cardBrand,
                }"
              />
            </div>
          </Transition>
        </template>
      </AppInputMask>

      <div class="grid sm:grid-cols-2">
        <div class="col-span-1 sm:pr-2">
          <AppInputText
            disabled
            v-model="form.expirationDate"
            :helper-text="$t('ComponentAddPaymentCardModal.ExpirationDateHelperText').value"
            analytics-name="add-payment-card-modal-expiry"
            :label="$t('ComponentAddPaymentCardModal.ExpirationDateLabel').value"
            :placeholder="$t('ComponentAddPaymentCardModal.PlaceholderExpirationDate').value"
            autocomplete="off"
            inputmode="numeric"
          />
        </div>
        <div class="col-span-1 sm:pl-2"></div>
      </div>

      <AppInputText
        disabled
        :value="`${selectedCard.firstName} ${selectedCard.lastName}`"
        :label="$t('AppPaymentCardModal.CardHolderName').value"
      />

      <!-- billing address -->
      <AppCard class="address-details">
        <div class="title-wrapper">
          <p class="billing-address-title">
            {{ $t('AppPaymentCardModal.BillingAddressTitle').value }}
          </p>
          <p class="edit" @click="onBillingAddress">Edit</p>
        </div>
        <p class="address-text" v-html="generateAddressString"></p>
      </AppCard>
    </AppModal>

    <BillingAddressModal
      v-if="showBillingAddressEditModal"
      :key="'billingAddres-visible-' + showBillingAddressEditModal"
      v-model="showBillingAddressEditModal"
      @onSave="onBillingAddressSave"
      :stored-address="billingAddress"
      :edit-card-title="editCardTitle"
    />
  </div>
</template>

<script>
import { reactive, ref, computed, toRef, onBeforeMount } from '@vue/composition-api'
import { numeric, helpers } from '@vuelidate/validators'
import { useValidation } from 'vue-composable'
import { useRouter } from '@galileo/composables/useRouter'
import BillingAddressModal from '@galileo/components/Views/PaymentMethod/BillingAddressModal'
import { useBillingAddressHelper } from '@galileo/composables/useAddress/useBillingAddressHelper'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import Address from '@galileo/models/Address/address'
import SettingsCard from '@galileo/components/Views/Account/SettingsCard/SettingsCard'
import { IconEdit, IconTrashCanFilled, IconTrashCan } from '@oen.web.vue2/icons'

import {
  useVModel,
  AppButton,
  AppCardFooter,
  AppInputText,
  AppInputMask,
  AppIcon,
  AppModal,
  AppModalHeader,
  AppInputDropdown,
  AppBackButton,
  AppDescriptionList,
  AppDescriptionListItem,
  AppFigure,
  AppListItem,
  AppListItemTitle,
  AppList,
  AppInputCheckbox,
  AppCard,
} from '@oen.web.vue2/ui'

import {
  useAuthStore,
  useI18nStore,
  useSendMoneyStore,
  usePaymentsStore,
  useCountriesStore,
} from '@galileo/stores'
import getValueRequiredValidation from '@galileo/utilities/validations.utility'

export default {
  name: 'CardDetailsModalComplete',
  components: {
    AppCard,
    AppInputCheckbox,
    AppList,
    AppFigure,
    AppListItemTitle,
    AppListItem,
    AppCardFooter,
    AppButton,
    IconEdit,
    AppInputText,
    AppInputMask,
    AppIcon,
    AppModal,
    AppModalHeader,
    AppInputDropdown,
    AppBackButton,
    AppDescriptionList,
    AppDescriptionListItem,
    SettingsCard,
    IconTrashCanFilled,
    BillingAddressModal,
    IconTrashCan,
    XeBackButton,
  },
  emits: ['input'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isCvv: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const authStore = useAuthStore()
    const countriesStore = useCountriesStore()
    const router = useRouter()

    const paymentsStore = usePaymentsStore()
    const sendMoneyStore = useSendMoneyStore()

    const country = sendMoneyStore.getCountryTo

    const countriesList = countriesStore.getCountries

    const user = authStore.userProfile

    const selectedCard = paymentsStore.selectedPaymentMethod

    const { expiryDate, cardBrand, cardPspCode, typeId } = selectedCard

    const { getUpdatedBillingAddressDetails } = useBillingAddressHelper($t)
    const valueRequired = getValueRequiredValidation()
    const billingAddressDetails = ref([])
    const form = reactive({
      cardholderName: user.customer.accountName,
      cardNumber: '',
      cardType: typeId,
      expirationDate: expiryDate,
      securityCode: '',
      cardBrand,
      cardPspCode,
      saveCard: true,
    })

    const editCardTitle = computed(() => {
      return `${selectedCard.type} ${selectedCard.maskedAccountNumber}`
    })

    const dottedCardNumber = computed(() => {
      const cardNum = selectedCard.maskedAccountNumber.replaceAll('.', '•')
      const lastDotIndex = cardNum.lastIndexOf('•')
      if (lastDotIndex !== -1) {
        return cardNum.slice(0, lastDotIndex + 1) + ' ' + cardNum.slice(lastDotIndex + 1)
      }
      return cardNum
    })

    onBeforeMount(async () => {
      if (!selectedCard.billingStreet) {
        router.go(-1)
      }

      if (selectedCard.maskedAccountNumber) {
        //replacing the dots on the masked card with x
        const cardNumberMasked = selectedCard.maskedAccountNumber.replaceAll('.', 'x')
        //we add as much x as the card number should be
        form.cardNumber = cardNumberMasked.padStart(16, 'x')
      }

      if (!props.isCvv) {
        form.securityCode = 'xxx'
      }

      //[TODO] improve
      const isVisa = selectedCard.title.includes('visa')

      if (isVisa) {
        form.cardBrand = 'VISA'
      } else {
        form.cardBrand = 'MASTERCARD'
      }

      mapBillingAddressFronSelectedCard()

      billingAddressDetails.value = getUpdatedBillingAddressDetails(billingAddress.value.country)
    })

    const minLenghtCVVCode = helpers.regex(/^.{3,3}$/i)

    const createValidation = () => {
      return useValidation({
        securityCode: {
          $value: toRef(form, 'securityCode'),
          valueRequired,
          numeric: {
            $validator: function (val) {
              if (props.isCvv) {
                return numeric.$validator(val)
              }
              return true
            },
            $message: numeric.$message,
          },
          minLegth: {
            $validator: minLenghtCVVCode,
            $message: $t('CardPayment.InvalidCVVErrorText').value,
          },
        },
      })
    }

    const validation = ref(createValidation())

    //#region Billing Address
    const showBillingAddressEditModal = ref(false)
    const billingAddress = ref()

    const mapBillingAddressFronSelectedCard = () => {
      billingAddress.value = new Address(
        selectedCard.billingStreet,
        selectedCard.billingCity,
        selectedCard.billingState,
        selectedCard.billingPostalCode,
        selectedCard.billingCountry
      )
    }

    const onBillingAddress = () => {
      paymentsStore.editSelectedPaymentMethod(form)
      showBillingAddressEditModal.value = true
    }

    const onBillingAddressSave = (address) => {
      if (address.billingAddress2) {
        address.billingAddress = `${address.billingAddress}, ${address.billingAddress2}`
      }

      billingAddress.value = new Address(
        address.billingAddress,
        address.billingCity,
        address.billingState,
        address.billingPostalCode,
        address.billingCountry
      )
      billingAddressDetails.value = getUpdatedBillingAddressDetails(billingAddress.value.country)
      showBillingAddressEditModal.value = false
    }
    //#endregion

    const submit = async () => {
      if (props.isCvv) {
        emit('submit', form.securityCode)
      }
      model.value = false
    }

    const close = () => {
      document.body.classList.remove('overflow-hidden')
      if (props.isCvv) {
        model.value = false
      } else {
        router.toParentRoute()
      }
    }

    const onDelete = () => {
      router.push({ path: 'delete' })
    }

    function getCountry(country) {
      let result = country
      result = countriesList.find((cty) => cty.value === country)

      if (result) {
        return result?.text
      }
    }

    const generateAddressString = computed(() => {
      const address = billingAddress.value
      return `
      <span>${address.street}</span><br/>
      <span>${address.city}, ${address.state} ${address.postalCode}</span><br/>
      <span>${getCountry(address.country)}</span>
      `
    })

    return {
      onBillingAddress,
      form,
      selectedCard,
      billingAddress,
      submit,
      country,
      model,
      validation,
      billingAddressDetailsFields: computed(() => billingAddressDetails.value),
      $t,
      close,
      onDelete,
      showBillingAddressEditModal,
      onBillingAddressSave,
      editCardTitle,
      dottedCardNumber,
      generateAddressString,
    }
  },
}
</script>

<style scoped>
.card-info {
  @apply mb-2 text-secondary-text;

  .card-type {
    @apply capitalize;
  }
}

.details-p {
  @apply text-secondary-text text-center text-base mb-8;
}

.ba-title {
  @apply text-primary-text text-lg font-medium;
}

.address-short {
  @apply text-xs;
}

::v-deep .list-item-title {
  @apply font-medium text-base;
}

::v-deep .list-item-caption {
  @apply text-xs font-normal;
}

::v-deep .figure {
  @apply shadow-ria-2;
  background-color: white;
}

.xe-figure {
  @apply font-semibold text-sm;
}

.card-images {
  display: flex;
  justify-content: right;
  width: 60px;

  img {
    @apply inline-block;
    @apply mr-2;
  }
}

::v-deep .card-footer > .button {
  @apply w-full !important;
}
::v-deep .card-footer > .button:disabled {
  @apply bg-blue-button-disabled text-white !important;
}

::v-deep .add-margin {
  @media md {
    .overlay-content {
      @apply mt-18 !important;
    }
  }
}
.checkbox-container {
  @apply mt-8 p-0 shadow-ria-1;
  border: 0.5px solid #dddddd;
}

::v-deep .checkbox-container .card-content {
  @apply m-0 p-2 py-4;
}

::v-deep .checkbox-container .card-content-block {
  @apply m-0;
}

.checkbox-container .checkbox-row {
  @apply text-secondary-text text-sm;
  @apply flex justify-start items-center;
}

::v-deep .billing-address-card .card-header {
  @apply px-7 !important;
}

.card-warning {
  @apply p-2 pl-3 pr-3 mb-4;
  background-color: theme('colors.blue.tertiary');
  color: #e57049;
  font-size: 14px;
  border-radius: 4px;
}
::v-deep .billing-address-card .card-content {
  @apply px-7 !important;
}

.icon-trash-can.button.button--icon {
  @screen sm {
    @apply w-6 h-6 !important;
    &:hover:not(:disabled) {
      @apply bg-transparent;
    }
    ::v-deep .button-inner {
      @apply w-6 h-6;
      @apply text-icon-primary;
      &:hover {
        @apply text-gray-darker;
      }
    }
  }
}

.icon-trash-can.button {
  @apply w-auto !important;
}

.card-details-title {
  @apply text-2xl mb-4 font-semibold;
}

.card-details-description {
  @apply text-sm text-gray-text mb-6;
}

/* Billing address styles */

.address-details {
  @apply p-6;
  box-shadow: 0px 3px 15px 0px #0011330d;
  border: 1px solid #ddd;

  .title-wrapper {
    @apply flex items-center justify-between pb-3;
    border-bottom: 1px solid #f3f4f6;
  }
  .billing-address-title {
    @apply uppercase font-semibold text-tertiary-text;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
  }

  .edit {
    @apply uppercase font-semibold cursor-pointer;
    font-size: 12px;
    line-height: 16px;
    color: var(--theme-color-primary, theme('colors.blue.default'));
  }

  .address-text {
    @apply mt-3 font-normal text-tertiary-text;
    font-size: 14px;
    line-height: 22px;
  }

  ::v-deep .card-content {
    @apply p-0;
    .card-content-block {
      @apply mb-0;
    }
  }
}
</style>
